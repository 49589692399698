<template>
<div style="width: 100%; height: 100%;">
  <iframe :src="url" width="100%" height="100%" style="border: none"></iframe>
  <div class="btns">
    <van-button round block type="info" @click="close"
    >关闭</van-button
    >
  </div>
</div>
</template>

<script>
export default {
name: "pdfView",
  props:['pdfUrl'],
  data () {
    return {
      url: ''
    }
  },
  methods: {
    close () {
      this.$emit('pdfClose')
    }
  },
  mounted() {
    this.url = '../../../static/pdf/web/viewer.html?file=' + encodeURIComponent(this.pdfUrl)
  }
}
</script>

<style scoped>

</style>
