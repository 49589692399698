<template>
  <div class="notice">
    <!-- <top-bar :title="this.$route.query.title" :left="true"></top-bar> -->
    <div class="workGoal">
      <div style="width: 100%; margin: 0 auto; height: 100%">
        <div
            style="
            font-size: 16px;
            font-weight: 620;
            line-height: 20px;
            word-break: break-all;
          "
        >
          {{ openVillageDate.title }}
        </div>
        <div style="font-size: 14px; color: rgba(0, 0, 0, 0.5)">
          {{ openVillageDate.createTime }}
        </div>
        <div
            style="
            border-top: 1px solid black;
            transform: scaleY(0.1);
            width: 100%;
            margin: 17px auto;
          "
        ></div>
        <div class="textConten">{{ openVillageDate.openDesc }}</div>
        <div
            style="width: 78vw; margin: 10px auto;;"
            v-for="item in openVillageDate.fileMessages"
            :key="item.id"
        >
          <el-image style="width:100%; min-height:200px;" v-if="item.fileType == 'image'" :src="item.objectUrl" alt="" :preview-src-list="[item.objectUrl]"></el-image>
          <a v-else href="javascript:;" class="pdf" @click="pdfShow(item.objectUrl, item.oldFileName)">{{item.oldFileName}}.pdf</a>
        </div>
      </div>
    </div>
    <div v-if="pdfFlag" class="pdfBox">
      <pdf-view :pdf-url="pdfurl" @pdfClose="pdfClose"/>
    </div>
  </div>
</template>

<script>
import topBar from "@/components/topBar/topBar";
import pdfView from "@/components/pdfView/pdfView";
export default {
  components: {
    topBar,
    pdfView
  },
  data() {
    return {
      openVillageDate: {},
      pdfFlag: false,
      pdfurl: ''
    };
  },
  methods: {
    pdfClose () {
      this.pdfFlag = false
    },
    pdfShow (objectUrl, name) {
      this.pdfurl = objectUrl
      if (window.__wxjs_environment === 'miniprogram') {
        //获取userAgent信息
        var user_agent = navigator.userAgent;
        if (user_agent.indexOf('Android') > -1 || user_agent.indexOf('Adr') > -1) {
          wx.miniProgram.navigateTo({
            url: '/pages/web/pdfDownload?returnUrl=' + encodeURIComponent(objectUrl)
          })
        } else {
          this.pdfFlag = true
        }
      } else {
        this.pdfFlag = true
      }
    },
    getDataInfo(userId) {
      this.$toast.loading({
        duration: 0,
        message: "加载中...",
        forbidClick: true,
      });
      this.$http({
        url: this.$http.adornUrl("/wxapp/app/appjqaffairsopen/info/" + userId),
        method: "post",
        params: this.$http.adornParams({
          userId: this.$globalData.userInfo.userId,
          community: this.$orgId
        })
      }).then(
          ({ data }) => {
            if (data.code == 0) {
              // 加载状态结束
              this.openVillageDate = data.appJqAffairsOpen;
              this.loading = false;
              this.$toast.clear();
            }
            this.$toast.clear();
          },
          (err) => {
            this.$toast.clear();
          }
      );
    },
  },
  mounted() {
    let userId = this.$route.query.userId;
    this.getDataInfo(userId);
  },
};
</script>

<style scoped>
.notice {
  width: 100vw;
  background-color: #f6f5f3;
  overflow: scroll;
}
.workGoal {
  padding: 30px;
  background-color: white;
  /* margin: 150px auto; */
}
.textConten {
  word-wrap: break-word;
  font-size: 26px;
}
.pdf {
  color: #0a84ff;
  font-size: 28px;
}
.pdfBox {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
}
</style>
